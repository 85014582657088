<template>
  <span v-if="!edit" class="px-3" v-on:dblclick="edit = true">
    <img :src="icon" class="height-1rem me-2" alt="" v-if="icon" />
    {{
      value > 0 && (row?.values?.length ?? 0) > 0
        ? row?.values.find((e) => e[row?.selectKey] == value)[row?.label]
        : " - "
    }}
  </span>
  <v-select
    v-else
    :options="
      row?.values.filter(
        (e) =>
          e.supported_coutries === null ||
          e.supported_coutries.indexOf(extraValue) > -1
      )
    "
    v-model="value"
    :label="row?.label"
    :placeholder="$t(`finance.notSelected`)"
    :reduce="(c) => c[row?.selectKey]"
    :dropdown-should-open="() => edit"
    v-on:focusout="focusOut"
    append-to-body
    clearable
    taggable
  />
</template>

<script>
export default {
  name: "DeliveryMethodSelector",
  props: {
    id: Number,
    row: [Object, Array],
    itemType: String,
    modelValue: [Object, Array, Number, String],
    extraValue: [String],
  },
  data() {
    return {
      value: this.modelValue,
      edit: false,
    };
  },
  computed: {
    storageUrl() {
      return process.env.VUE_APP_FILES;
    },
    icon() {
      if ((this.row?.values?.length ?? 0) < 1) {
        return null;
      }

      let icon = this.row?.values?.find(
        (e) => e[this.row?.selectKey] == this.value
      );
      if (icon === undefined || icon === null) {
        return null;
      }
      icon = icon?.icon;

      if (icon.indexOf("http") !== 0 && icon.indexOf("//") !== 0) {
        icon = this.storageUrl + "/" + icon;
      }

      return icon;
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("update:modelValue", this.value);
        this.$emit("update", this.value);
        this.$emit("change");
      }
      this.edit = false;
    },
    modelValue: {
      deep: true,
      handler() {
        this.value = this.modelValue;
      },
    },
  },
  methods: {
    focusOut() {
      this.edit = false;
    },
  },
  emits: ["update:modelValue", "update", "change"],
};
</script>
